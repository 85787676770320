<template>
  <div>
    <!-- 常用问题说明 -->

    <Head />
    <div class="main">
      <h2>FAQ</h2>
      <!-- 1. Payment -->
      <h3>1. Payment</h3>
      <h4>Settlement method</h4>
      <p style="margin-bottom: 20px;">At the moment, settlement can be made by bank transfer in the United States or
        payment on demand after prepayment
        made in advance. Credit card payment is currently not accepted.</p>
      <h4>The reasons for paying in two installments</h4>
      <p> The volume and weight of the goods cannot be accurately quoted because the goods have not been received when the
        order is quoted. The related international shipping fee, domestic delivery fee in the United States and
        value-added
        tax cannot be accurately calculated. Therefore we split the settlement of the fee into two installments.</p>
      <p style="margin-top: 8px;"> The first instalment is related to domestic expenses in China, including the price of
        goods, domestic shipping,
        additional service fees, handling fees and other costs that occur in China.</p>
      <p style="margin-top: 8px;margin-bottom: 40px;"> The second instalment is the cost of international delivery. After
        the goods arrive,
        we will measure the actual
        volume and weight of the packing and then submit an accurate quotation for international freight.</p>
      <!--2. Transportation and customs clearance  -->
      <h3>2. Transportation and customs clearance</h3>
      <h4>Choosing international shipping companies</h4>
      <p style="margin-bottom: 8px;"> Rakumart will choose reliable, fast and affordable international logistics channels
        to transport your goods. There
        are many freight companies engaged in international transportation between China and the United States, but many
        of
        them often damage or lose goods; some transportation companies charge very expensive international freight and
        customs clearance fees.</p>
      <p style="margin-bottom: 20px;"> After careful assessment, Rakumart will recommend you a suitable transportation
        company
        after taking into account factors such as freight costs, transportation timeliness, and service stability. In the
        future, we will continue to recommend you more suitable transportation routes.</p>

      <!-- /Customs clearance -->
      <h4>Customs clearance</h4>
      <p style="margin-bottom: 10px;">By default, the goods you purchase will be delivered directly to you by our
        cooperating logistics company after customs clearance.</p>
      <p style="margin-bottom: 20px;">
        Please contact us in advance if you want to clear the customs by yourself.</p>
      <!-- Goods delivery in the United States -->
      <h4>Goods delivery in the United States</h4>
      <p style="margin-bottom: 20px;">In general, the international shipping fee already includes the cost of domestic
        delivery in the United
        States.
        If the delivery address is remote or there are extra charges incurred under special circumstances, we will explain
        this charge to you in advance, and charge it separately when quoting.</p>
      <h4>Whether we provide shipping only agency services (customers purchase goods by themselves and entrust us to ship)
      </h4>
      <p style="margin-bottom: 40px;">We are very sorry that if we are unable to accurately control the source of the
        goods due to quality control and
        intellectual property protection, we will not provide shipping only agency services.</p>
      <!-- 3. The sellers and products -->
      <h3>3. The sellers and products</h3>
      <h4>The price of the goods on the websites</h4>
      <p style="margin-bottom: 8px;"> In most cases, you can buy the goods at the listed price as shown on the web pages.
        Several reasons may cause the
        actual price to be different from the price listed.</p>
      <p style="margin-bottom: 8px;"> In some cases, the price on the links does not match the actual purchase price, and
        the actual product price may be
        greater than that of on the web pages.</p>
      <p style="margin-bottom: 8px;"> For symmetrical two-piece products (such as gloves) or products that are placed left
        and right, some sellers will
        list the price as a single product.</p>
      <p style="margin-bottom: 8px;"> Customized items cannot be priced. The price marked by the seller may only be a
        nominal price and does not include
        the fee related to customization services.</p>
      <p style="margin-bottom: 20px;"> We will notify you the above reason(s) when we reply your order enquiry.</p>

      <!-- Changes in material, color and quality -->
      <h4>Changes in material, color and quality</h4>
      <p style="margin-bottom: 8px;"> There may be some differences between different batches of some goods.</p>
      <p style="margin-bottom: 8px;"> Some sellers may transfer goods from their counterparts when they are out of stock.
      </p>
      <p style="margin-bottom: 8px;"> Change of source of goods. When the manufacturer makes changes, there may be
        differences in material, colour,
        quality etc.</p>
      <p style="margin-bottom: 8px;"> In the process of factory production, due to reasons such as cost reduction and raw
        material out of stock, there may
        be situations such as adjustments of raw material suppliers, which will lead to changes in product materials etc.
      </p>
      <p style="margin-bottom: 20px;"> In order to maintain consistent quality, you can choose OEM customization.</p>
      <!-- Promotional items/ goods that are cheaper than the market price -->
      <h4>Promotional items/ goods that are cheaper than the market price</h4>

      <p style="margin-bottom: 8px;"> Some sellers sell products at a much lower price than similar products on the
        market. Such products are often either
        for stock clearing or defective. If there is a quality problem after the purchase, it is likely that the seller
        will
        not return or exchange goods. It is recommended that you do not choose products whose prices are significantly
        lower
        than the market price when purchasing.</p>
      <p style="margin-bottom: 40px;"> If you place an order at Rakumart, we will remark this kind of items to you when
        the order is quoted. If you need to
        confirm the sample, you can request a quotation after adjusting the quantity. If you proceed to pay for your
        order,
        we will assume that you are aware of such issues.</p>
      <!-- 4. After-sales -->
      <h3>4. After-sales</h3>
      <h4>Return and exchange instructions for purchased products in China</h4>
      <p style="margin-bottom: 8px"> The return or exchange of goods may incur some domestic shipping costs in China. We
        will try our best to help you
        negotiate and ask the seller to bear or partially bear such shipping costs. There are mainly the following
        reasons:</p>
      <p style="margin-bottom: 8px">
        For products with low unit price, the round-trip shipping cost for return may sometimes be more expensive than the
        price of the product itself. Sellers are often unwilling to bear the shipping cost for return and exchange.</p>
      <p style="margin-bottom: 8px"> Different sellers have different criteria for defective products, and there may be
        cases where some sellers do not
        accept the products we feed back as defective products.</p>
      <p style="margin-bottom: 8px"> OEM customized products, if the products are not obviously defective due to the
        production process, then they cannot
        be returned.</p>
      <p style="margin-bottom: 8px"> After you pay for the order, we will refund you the goods that are not purchased and
        do not charge any handling
        fees.</p>
      <p style="margin-bottom: 10px"> In terms of the goods that have been purchased but are still in China, whether it is
        possible to return them and get
        a refund, it can only be determined after negotiation with sellers. We will try our best to help you negotiate
        returns and refunds with sellers. RAKUMART will deduct the handling fees from the fee refunded to you as we have
        completed our work.</p>
      <p class="note"> <span>Note:</span> When the goods are in the process of the international transportation, the
        return
        and
        refund request initiated by the customer's own reasons will not be processed.</p>
      <!-- The damage caused by us -->
      <h4>The damage caused by us</h4>
      <p style="margin-bottom: 8px;"> If your goods cannot be delivered in time due to our fault, you can return the goods
        for a refund, and no handling
        fee will be incurred.</p>
      <p style="margin-bottom: 20px;"> We will be responsible for the damages due to our business negligence. You can
        choose to purchase same goods at our
        expense or get a refund.</p>

      <h4>The damage caused by the logistics provider</h4>
      <p>RAKUMART is a goods purchasing service provider, we are not a goods supplier nor a seller. After the goods leave
        our warehouse and after the bill of lading number, delivery number, etc. can be traced to verify that the goods
        have been delivered to the logistics company, RAKUMART has fulfilled the obligation to complete the order
        delivery, and the ownership of the goods has been transferred. In the process of cargo transportation, if there is
        obvious evidence to prove that the goods are damaged or lost due to the logistics service provider. We will assist
        our customer find out the reasons, identify the responsibility, negotiate with the logistics service provider,
        seek compensation, and try our best to minimize customer‘s losses.</p>














    </div>
    <Foot />
  </div>
</template>
<script>
import Head from '../../components/head/HomePageTop.vue'
import Foot from '../../components/foot/Foot'
export default {
  data() {
    return {

    }
  },
  components: {
    Head,
    Foot,
  },
  computed: {

  },
  created() { },
  methods: {},
}
</script>
<style lang="scss" scoped="scoped">
@import '../../css/mixin.scss';

.main {
  width: 1000px;
  margin: auto;
  margin-top: -25px;
  padding-top: 87px;
  padding-bottom: 81px;

  h2 {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #222222;
    margin-bottom: 46px;
  }

  h3 {
    width: 100%;
    font-size: 21px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #222222;
    margin-bottom: 20px;
  }

  h4 {
    width: 100%;
    font-size: 16px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #222222;
    margin-bottom: 10px;
  }

  p {
    width: 100%;
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #222222;
    line-height: 24px;
  }

  .note {
    span {
      color: #E74C3C;
    }

    font-size: 16px;
    font-family: Roboto-Medium,
    Roboto;
    font-weight: 500;
    color: #666666;
    line-height: 24px;
  }

  margin-bottom: 20px;
}
</style>
